<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && isOld && !disabled"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="utvCheck"
                  mappingType="PUT"
                  label="완료"
                  icon="check"
                  @beforeAction="completeInfo"
                  @btnCallback="completeCallback" />
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="utvCheck"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
                <c-btn
                  v-show="editable && isOld && !disabled"
                  label="삭제"
                  icon="delete_forever"
                  @btnClicked="deleteInfo" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <c-plant
                  :required="true"
                  :editable="editable"
                  :disabled="isOld"
                  type="edit"
                  name="plantCd"
                  v-model="utvCheck.plantCd" />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  :disabled="disabled"
                  type="datetime"
                  default="today"
                  label="보고일시"
                  name="reportDate"
                  v-model="utvCheck.reportDate" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-fire-fighting-utv
                  isMultiple
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="UTV 소방차" 
                  name="sopFireFightingUtvIds" 
                  v-model="utvCheck.sopFireFightingUtvIds" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  required
                  :editable="editable"
                  :disabled="disabled"
                  :rows="1"
                  label="내용" 
                  name="contents" 
                  v-model="utvCheck.contents" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="1"
                  label="미흡사항" 
                  name="insufficiencies" 
                  v-model="utvCheck.insufficiencies" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="1"
                  label="조치내역" 
                  name="actionDetails" 
                  v-model="utvCheck.actionDetails" />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <c-datepicker
                  :editable="editable"
                  :disabled="disabled"
                  type="datetime"
                  default="today"
                  label="사용일시"
                  name="useDt"
                  v-model="utvCheck.useDt" />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <c-field
                  :editable="editable"
                  :disabled="disabled"
                  :data="utvCheck"
                  deptValue="deptCd"
                  type="dept_user"
                  label="사용자"
                  name="userId"
                  v-model="utvCheck.userId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="1"
                  label="사용내역"
                  name="usageHistory"
                  v-model="utvCheck.usageHistory">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="1"
                  label="기타사항" 
                  name="etc" 
                  v-model="utvCheck.etc" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="1"
                  label="비고" 
                  name="remarks" 
                  v-model="utvCheck.remarks" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable&&!disabled"
                  label="관련 사진">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-table
            ref="table"
            title="UTV 소방차 점검 항목"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="utvCheck.results"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            hideBottom
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="sopFireFightingUtvCheckResultId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn 
                  v-if="editable&&!disabled" 
                  :showLoading="false" 
                  label="LBLADD" 
                  icon="add" 
                  @btnClicked="addResult" />
                <c-btn 
                  v-if="editable&&!disabled" 
                  :disabled="!utvCheck.results || utvCheck.results.length === 0"
                  label="제외" 
                  icon="remove" 
                  @btnClicked="deleteResult" />
              </q-btn-group>
            </template>
          </c-table>
          <c-card title="UTV소방차 점검사진" class="cardClassDetailForm">
            <template slot="card-button">
              <c-pick-file :editable="editable" :disabled="disabled" outlined v-model="file" :dense="true" @getImageData="getImageData">
                <template v-slot:prepend>
                  <q-icon name="attach_file"></q-icon>
                </template>
              </c-pick-file>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <q-img :src="utvCheck.utvCheckSrc"></q-img>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingUtvCheckInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingUtvCheckId: '',
      }),
    },
    utvCheck: {
      type: Object,
      default: () => ({
        sopFireFightingUtvCheckId: '',  // UTV점검 일련번호
        plantCd: null,  // 사업장코드
        reportDate: '',  // 보고일시
        sopFireFightingUtvIds: '',  // UTV 소방차 (복수)
        contents: '',  // 내용
        insufficiencies: '',  // 미흡사항
        actionDetails: '',  // 조치내역
        useDt: '',  // 사용일시
        userId: '',  // 사용자
        usageHistory: '',  // 사용내역
        etc: '',  // 기타사항
        remarks: '',  // 비고
        utvCheckSrc: '',  // UTV소방창 점검사진
        completeFlag: 'N',  // 완료여부
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        results: [], // 점검결과
        deleteResults: [], // 점검결과
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'FIRE_UTV_CHECK',
        taskKey: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      file: null,
      grid: {
        columns: [
          {
            name: 'itemName',
            field: 'itemName',
            label: '점검항목',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'resultContents',
            field: 'resultContents',
            label: '점검결과',
            align: 'left',
            style: 'width:300px',
            type: 'textarea',
            sortable: false,
          },
        ],
        height: '300px'
      },
      editable: true,
      isSave: false,
      isComplete: false,
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.fft.fireFightingUtvCheck.update.url
      this.completeUrl = transactionConfig.fft.fireFightingUtvCheck.complete.url
      this.deleteUrl = transactionConfig.fft.fireFightingUtvCheck.delete.url
      // code setting
      // list setting
    },
    getImageData(data) {
      this.$set(this.utvCheck, 'utvCheckSrc', data)
    },
    addResult() {
      this.popupOptions.title = 'UTV 점검항목 검색';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/fft/base/fireFightingUtvItemPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeItemPopup;
    },
    closeItemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.utvCheck.results, { sopFireFightingUtvCheckItemId: item.sopFireFightingUtvCheckItemId }) === -1) {
            this.utvCheck.results.splice(0, 0, { 
              sopFireFightingUtvCheckResultId: uid(),  // UTV점검항목별 결과 일련번호
              sopFireFightingUtvCheckId: this.popupParam.sopFireFightingUtvCheckId,  // UTV점검 일련번호
              sopFireFightingUtvCheckItemId: item.sopFireFightingUtvCheckItemId,  // UTV 점검항목 일련번호
              itemName: item.itemName,  // 점검항목
              resultContents: '',  // 점검결과
              editFlag: 'C', 
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    deleteResult() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '제외하시겠습니까? ',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.utvCheck.deleteResults) this.utvCheck.deleteResults = [];
            this.$_.forEach(selectData, item => {
              if (this.$_.findIndex(this.utvCheck.deleteResults, { sopFireFightingUtvCheckResultId: item.sopFireFightingUtvCheckResultId }) === -1
                && item.editFlag !== 'C') {
                  this.utvCheck.deleteResults.push(item)
              }
              this.utvCheck.results = this.$_.reject(this.utvCheck.results, item)
            })
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingUtvCheck.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingUtvCheck.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.utvCheck.results)) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.utvCheck.regUserId = this.$store.getters.user.userId;
              this.utvCheck.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.sopFireFightingUtvCheckId) {
        this.$set(this.attachInfo, 'taskKey', result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.$set(this.popupParam, 'sopFireFightingUtvCheckId', result.data)
      this.$emit('getDetail');
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingUtvCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.utvCheck.results)) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '완료하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.utvCheck.chgUserId = this.$store.getters.user.userId;

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail');
    },
  }
};
</script>